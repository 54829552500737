<template>
  <div class="app-content content" :class="[{'show-overlay': $store.state.app.shallShowOverlay}]">
    <div class="content-overlay" />
    <div class="header-navbar-shadow" />
    <div class="content-wrapper">
      <div class="content-body">
        <transition name="zoom-fade" mode="out-in">
          <div class="card">
            <div class="m-2">
              <div class="row">
                <div class="col-12">Всего на складе {{ specifications.total_in_stock }} штук</div>
                <div class="col-12">
                  Всего денег на складе
                  <span v-for="(currency,index) in specifications.total_costs" :key="index">
                    {{ currency.currency_name }}: {{ currency.amount }}&nbsp;
                  </span>
                </div>
              </div>
            </div>
            <div class="mx-2 mb-2">
              <div class="row">
                <div class=" col-12 col-sm-6 d-flex align-items-center justify-content-center justify-content-sm-start">
                  <span class="text-muted">
                    Показано с {{ specifications.from }} по {{ specifications.to }} из {{ specifications.total }} записей
                  </span>
                </div>
                <div class="col-12 col-sm-6 d-flex align-items-center justify-content-center justify-content-sm-end">
                  <b-pagination
                      v-model="currentPage"
                      @input="changePage"
                      :total-rows="specifications.total"
                      :per-page="specifications.per_page"
                      first-number
                      last-number
                      class="mb-0 mt-1 mt-sm-0"
                      prev-class="prev-item"
                      next-class="next-item"
                  >
                    <template #prev-text>
                      <feather-icon icon="ChevronLeftIcon" size="18"/>
                    </template>
                    <template #next-text>
                      <feather-icon icon="ChevronRightIcon" size="18"/>
                    </template>
                  </b-pagination>
                </div>
              </div>
            </div>
            <div class="position-relative table-responsive">
              <table class="table b-table">
                <thead>
                <tr>
                  <th>#</th>
                  <th>Дата прихода</th>
                  <th>Товар</th>
                  <th>Кол-во</th>
                  <th>Вход</th>
                  <th>Себе-сть</th>
                  <th></th>
                  <th>Тип цены</th>
                  <th>Цена</th>
                  <th>Скидка</th>
                  <th>Дата изменения</th>
                </tr>
                </thead>
                <tbody>
                <template v-for="specification in specifications.data">
                  <tr :key="specification.product_specification_id">
                    <td class="font-weight-bold" rowspan="4">#{{ specification.product_specification_id }}</td>
                    <td rowspan="4">{{ $formatters.formattedDate(specification.batch.created_at) }}</td>
                    <td rowspan="4">
                      {{ specification.product_specification.product.name }}
                      {{ specification.product_specification.product.brand_name }}
                      ({{ specification.product_specification.product.sku }})
                    </td>
                    <td rowspan="4">{{ specification.in_stock }} / {{ specification.total_qty }}</td>
                    <td rowspan="4">
                      <span class="amount positive">{{ specification.batch.cost }}</span>
                      <span class="currency">{{ specification.currency.name }}</span>
                    </td>
                    <td rowspan="4">
                      <span class="amount positive">{{ specification.uah_cost }}</span>
                      <span class="currency">UAH</span>
                    </td>
                    <td rowspan="4">
                      <button
                        class="btn btn-outline-success btn-block"
                        @click="changePrice(specification.product_specification.id, 'raise')"
                      >
                        Поднять 5%
                      </button><br>
                      <button
                        class="btn btn-outline-danger btn-block"
                        @click="changePrice(specification.product_specification.id, 'discount')"
                      >
                        Снизить 5%
                      </button><br>
                      <button
                        class="btn btn-outline-primary btn-block"
                        @click="changePrice(specification.product_specification.id, 'change')"
                      >
                        Пересчитать
                      </button>
                    </td>
                  </tr>
                  <tr :key="specification.product_specification_id+'_key'">
                    <td>Кл. партнер</td>
                    <td>
                      <span class="amount positive">{{ keyGroupPrice(specification).discount_price }}</span>
                      <span class="currency">UAH</span>
                    </td>
                    <td>
                      <span
                        v-if="keyGroupPrice(specification).discount_percent > 0"
                        :class="['discount', keyGroupPrice(specification).discount_percent > 50 ? 'danger' : 'warning']"
                      >
                      {{ keyGroupPrice(specification).discount_percent }}%
                    </span>
                    </td>
                    <td>
                      {{ keyGroupPrice(specification).updated_at | formattedDate }}
                    </td>
                  </tr>
                  <tr :key="specification.product_specification_id+'_register'">
                    <td>Зарег. партнер</td>
                    <td>
                      <span class="amount positive">{{ registerGroupPrice(specification).discount_price }}</span>
                      <span class="currency">UAH</span>
                    </td>
                    <td>
                      <span
                        v-if="registerGroupPrice(specification).discount_percent > 0"
                        :class="['discount', registerGroupPrice(specification).discount_percent > 50 ? 'danger' : 'warning']"
                      >
                      {{ registerGroupPrice(specification).discount_percent }}%
                    </span>
                    </td>
                    <td>
                      {{ registerGroupPrice(specification).updated_at | formattedDate }}
                    </td>
                  </tr>
                  <tr :key="specification.product_specification_id+'_retail'">
                    <td>Розница</td>
                    <td>
                      <span class="amount positive">{{ retailGroupPrice(specification).discount_price }}</span>
                      <span class="currency">UAH</span>
                    </td>
                    <td>
                      <span
                        v-if="retailGroupPrice(specification).discount_percent > 0"
                        :class="['discount', retailGroupPrice(specification).discount_percent > 50 ? 'danger' : 'warning']"
                      >
                      {{ retailGroupPrice(specification).discount_percent }}%
                    </span>
                    </td>
                    <td>
                      {{ retailGroupPrice(specification).updated_at | formattedDate }}
                    </td>
                  </tr>
                </template>
                </tbody>
              </table>
            </div>
            <div class="mx-2 mb-2">
              <div class="row">
                <div class=" col-12 col-sm-6 d-flex align-items-center justify-content-center justify-content-sm-start">
                  <span class="text-muted">
                    Показано с {{ specifications.from }} по {{ specifications.to }} из {{ specifications.total }} записей
                  </span>
                </div>
                <div class="col-12 col-sm-6 d-flex align-items-center justify-content-center justify-content-sm-end">
                  <b-pagination
                      v-model="currentPage"
                      @input="changePage"
                      :total-rows="specifications.total"
                      :per-page="specifications.per_page"
                      first-number
                      last-number
                      class="mb-0 mt-1 mt-sm-0"
                      prev-class="prev-item"
                      next-class="next-item"
                  >
                    <template #prev-text>
                      <feather-icon icon="ChevronLeftIcon" size="18"/>
                    </template>
                    <template #next-text>
                      <feather-icon icon="ChevronRightIcon" size="18"/>
                    </template>
                  </b-pagination>
                </div>
              </div>
            </div>
          </div>
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
import { BPagination } from 'bootstrap-vue';

export default {
  name: 'Remains',
  components: { BPagination },
  data() {
    return {
      currentPage: 1,
      specifications: {
        current_page: 1,
        data: [],
        from: null,
        to: null,
      },
    };
  },
  async mounted() {
    await this.getSpecifications();
    if (this.$route.query.page) {
      this.currentPage = this.$route.query.page;
    }
  },
  methods: {
    async changePage(page) {
      this.specifications.current_page = page;
      await this.setFilters();
    },
    async changePrice(id, type) {
      try {
        await this.$api.productSpecifications.price.update(id, { type });
        this.$toast.success('Цена успешно изменена');
        await this.getSpecifications();
      } catch (error) {
        this.$toast.error(error.response.data.message);
      }
    },
    async setFilters() {
      this.loadFilters = true;
      const query = {
        ...(this.specifications.current_page > 1 && { page: this.specifications.current_page }),
      };

      await this.$router.replace({ query }).catch(() => {});
      await this.getSpecifications();
      this.loadFilters = false;
    },
    async getSpecifications() {
      const filters = { ...this.$route.query };
      filters.limit = 25;
      this.specifications = (await this.$api.productSpecifications.remains(filters)).data;
    },
    keyGroupPrice(specification) {
      return specification.prices.find((price) => price.id === 2).prices;
    },
    registerGroupPrice(specification) {
      return specification.prices.find((price) => price.id === 1).prices;
    },
    retailGroupPrice(specification) {
      return specification.prices.find((price) => price.id === 3).prices;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include';

.per-page-selector {
  width: 90px;
}

span.amount {
  font-size: 18px;
}
.positive {
  color: $success;
}
.negative {
  color: $danger;
}
span.currency {
  font-size: 10px;
}

.discount {
  text-transform: uppercase;
  &.warning {
    color: $warning;
    text-transform: uppercase;
  }
  &.danger {
    color: $danger;
  }
}
</style>
